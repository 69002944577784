<template>
  <b-overlay :show="isLoading" rounded="sm">
    <app-collapse accordion type="margin">
      <!-- <p class="p-2 bg-white">
            {{ menu.name }}
          </p> -->
      <app-collapse-item :title="menu.name">
        <b-row v-if="menuItemDetails">
          <b-col cols="12">
            <b-form @submit.prevent>
              <b-form-group label="نام فهرست دلخواه" label-for="name">
                <b-form-input
                  id="name"
                  trim
                  placeholder="درباره ما"
                  v-model="menuItemDetails.name"
                />
              </b-form-group>
            </b-form>
          </b-col>
          <b-col cols="12">
            <b-form @submit.prevent>
              <b-form-group label="نشانی پیوند" label-for="link">
                <b-form-input
                  dir="auto"
                  id="link"
                  trim
                  placeholder="about-us"
                  v-model="menuItemDetails.link"
                />
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
        <div
          class="w-100 d-flex align-items-center justify-content-end"
          style="gap: 1rem"
        >
          <b-button
            size="sm"
            variant="danger"
            @click="renderDeleteConfirmation"
          >
            حذف فهرست
          </b-button>
          <b-button size="sm" variant="success" @click="sendUpdateRequest">
            ویرایش
          </b-button>
        </div>
      </app-collapse-item>
      <slot name="recursive"></slot>
    </app-collapse>
    <!-- Delete Modal Start -->
    <b-modal
      :id="`menu-delete-modal-${menu.menuItemId}`"
      centered
      ok-variant="danger"
      title="حذف فهرست"
      ok-title="حذف"
      cancelTitle="انصراف"
      @ok="deleteMenuItem"
    >
      <template v-if="tempMenuItem">
        <span> آیا از حذف فهرست "{{ tempMenuItem.name }}" اطمینان دارید؟ </span>
      </template>
    </b-modal>
    <!-- Delete Modal End -->
  </b-overlay>
</template>
<script>
export default {
  emits: ["refetch"],
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  watch: {
    menu: {
      immediate: true,
      handler(val) {
        if (val) {
          this.menuItemDetails = JSON.parse(JSON.stringify(this.menu));
          // if (this.menuItemDetails.link.includes("/pages/")) {
          //   this.menuItemDetails.link =
          //     this.menuItemDetails.link.split("/pages/")[1];
          // }
        }
      },
    },
  },
  data() {
    return {
      isLoading: false,
      menuItemDetails: null,
      tempMenuItem: null,
    };
  },
  methods: {
    async deleteMenuItem() {
      try {
        this.isLoading = true;
        let _this = this;
        let deleteAMenuItem = new DeleteAMenuItem(_this);
        deleteAMenuItem.setRequestParam({ id: _this.tempMenuItem.menuItemId });
        await deleteAMenuItem.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `فهرست با موفقیت حذف شد.`,
              },
            });
            _this.$emit("refetch");
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    renderDeleteConfirmation() {
      this.tempMenuItem = JSON.parse(JSON.stringify(this.menu));
      this.$bvModal.show(`menu-delete-modal-${this.menu.menuItemId}`);
    },
    setLanguage(language) {
      this.menuItemDetails.languageId = language.languageId;
    },
    async sendUpdateRequest() {
      try {
        if (this.menuItemDetails.link && this.menuItemDetails.link != "#") {
          if (!this.menuItemDetails.link.startsWith("/")) {
            this.menuItemDetails.link = `/${this.menuItemDetails.link}`;
          }
        }
        this.menuItemDetails.seourl = this.menuItemDetails.link;
        this.isLoading = true;
        let _this = this;
        let updateAMenuItem = new UpdateAMenuItem(_this);
        updateAMenuItem.setRequestParamDataObj(_this.menuItemDetails);
        await updateAMenuItem.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `فهرست با موفقیت ویرایش شد.`,
              },
            });
            // if (_this.menuItemDetails.link.includes("/pages/")) {
            //   _this.menuItemDetails.link =
            //     _this.menuItemDetails.link.split("/pages/")[1];
            // }
            _this.$emit("refetch");
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    LanguagePicker,
  },
};
import { UpdateAMenuItem, DeleteAMenuItem } from "@/libs/Api/MenuItem";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import LanguagePicker from "../utils/LanguagePicker.vue";
import {
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BOverlay,
} from "bootstrap-vue";
</script>
