<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col cols="12">
        <b-form-group>
          <b-form-radio
            v-model="selectedPage"
            class="my-1"
            name="page"
            v-for="page in pageList"
            :key="page.pageId"
            :value="page"
          >
            {{ page.title }}
          </b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="w-100 d-flex align-items-center justify-content-end">
      <b-button
        :disabled="selectedPage ? false : true"
        variant="success"
        size="sm"
        @click="sendCreateMenuItemRequest"
      >
        افزودن
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
export default {
  props: {
    lastPriority: {
      type: [String, Number],
    },
    menuId: {
      type: [String, Number],
    },
    languageId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      pageList: null,
      selectedPage: null,
      menuItemDetails: {
        isDeleted: false,
        menuItemId: 0,
        menuId: null,
        name: null,
        link: null,
        seourl: null,
        seoTitle: null,
        seoDescription: null,
        languageId: null,
        mainId: "",
        pageId: null,
        parentId: null,
        priority: null,
        createDate: new Date(Date.now()).toISOString(),
      },
    };
  },
  watch: {
    languageId: {
      handler(val) {
        this.getAllPages();
      },
    },
  },
  methods: {
    async sendCreateMenuItemRequest() {
      this.menuItemDetails.languageId = this.languageId;
      this.menuItemDetails.menuId = this.menuId;
      this.menuItemDetails.name = this.selectedPage.title;
      this.menuItemDetails.link = this.selectedPage.link;
      // if (this.menuItemDetails.link && this.menuItemDetails.link != "#") {
      //   this.menuItemDetails.link = `${this.menuItemDetails.link}`;
      // }
      this.menuItemDetails.priority = this.lastPriority
        ? this.lastPriority + 1
        : 1;
      try {
        this.isLoading = true;
        let _this = this;
        let createNewMenuItem = new CreateNewMenuItem(_this);
        createNewMenuItem.setRequestParamDataObj(_this.menuItemDetails);
        await createNewMenuItem.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `فهرست ساخته شد.`,
              },
            });
            _this.menuItemDetails = {
              isDeleted: false,
              menuItemId: 0,
              menuId: null,
              name: null,
              link: null,
              seourl: null,
              seoTitle: null,
              seoDescription: null,
              mainId: "",
              pageId: null,
              parentId: null,
              priority: null,
              createDate: new Date(Date.now()).toISOString(),
            };
            _this.$emit("refetch");
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getAllPages() {
      try {
        this.isLoading = true;
        let _this = this;
        let getAllPages = new GetAllPages(_this);
        getAllPages.setRequestParam({
          count: 0,
          pageNumber: 0,
          languageId: _this.languageId,
        });
        await getAllPages.fetch((response) => {
          if (response.isSuccess) {
            _this.pageList = response.data.pages;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BOverlay,
  },
};
import { GetAllPages } from "@/libs/Api/Page";
import { CreateNewMenuItem } from "@/libs/Api/MenuItem";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BOverlay,
} from "bootstrap-vue";
</script>
