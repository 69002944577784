import WebServiceRequest from "../Api/WebServiceRequest";

class CreateNewMenuItem extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("MenuItem/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetAllMenuItems extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("MenuItem/GetAll");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class ChangeItemPriorities extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("MenuItem/ChangeItemsPriorities");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class UpdateAMenuItem extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("MenuItem/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class DeleteAMenuItem extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("MenuItem/DeleteBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

export {
  CreateNewMenuItem,
  GetAllMenuItems,
  ChangeItemPriorities,
  UpdateAMenuItem,
  DeleteAMenuItem,
};
