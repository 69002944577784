<template>
  <b-card bg-variant="secondary">
    <template #header>
      <h6 class="mb-0 text-white">ساختار فهرست</h6>
    </template>
    <div v-if="menuItemList && menuItemList.length > 0">
      <nested-draggable :menuItemList="menuItemList" @refetch="refetch" />

      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" @click="saveData"> ذخیره </b-button>
      </div>
    </div>
    <div v-else>
      <p class="text-center text-white">
        جهت نمایش، یکی از فهرست ها را از بالا انتخاب کنید
      </p>
    </div>
  </b-card>
</template>

<script>
export default {
  emits: ["handlePriorities", "refetch"],
  props: {
    menuItemList: {
      type: Array,
    },
  },
  methods: {
    saveData() {
      this.$emit("handlePriorities");
    },
    refetch() {
      this.$emit("refetch");
    },
  },
  components: {
    BCard,
    BRow,
    BButton,
    NestedDraggable,
  },
};
import { BCard, BRow, BButton } from "bootstrap-vue";
import NestedDraggable from "./NestedDraggable.vue";
</script>
