<template>
  <draggable :list="menuItemList" tag="ul" :group="{ name: 'g1' }">
    <li v-for="menu in menuItemList" :key="menu.priority" style="cursor: move">
      <single-menu-accordion :menu="menu" @refetch="refetch">
        <template v-slot:recursive>
          <nested-draggable :menuItemList="menu.children"></nested-draggable>
        </template>
      </single-menu-accordion>
    </li>
  </draggable>
</template>
<script>
export default {
  emits: ["refetch"],
  props: {
    menuItemList: {
      type: Array,
    },
  },
  name: "nested-draggable",
  data() {
    return {};
  },
  methods: {
    refetch() {
      this.$emit("refetch");
    },
  },
  components: {
    BCol,
    BCard,
    draggable,
    SingleMenuAccordion,
  },
};
import { BCol, BCard } from "bootstrap-vue";
import draggable from "vuedraggable";
import SingleMenuAccordion from "./SingleMenuAccordion.vue";
</script>
<style scoped></style>
