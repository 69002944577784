<template>
  <div class="main-container">
    <b-card bg-variant="secondary">
      <template #header>
        <h6 class="mb-0 text-white">افزودن گزینه های فهرست</h6>
      </template>
      <app-collapse accordion type="margin">
        <app-collapse-item title="برگه ها">
          <create-menu-item-from-pages
            :lastPriority="lastPriority"
            :menuId="menuId"
            @refetch="refetch"
            :languageId="languageId"
          ></create-menu-item-from-pages>
        </app-collapse-item>
        <app-collapse-item title="پیوند دلخواه">
          <create-raw-menu
            :menuId="menuId"
            :languageId="languageId"
            :lastPriority="lastPriority"
            @refetch="refetch"
          ></create-raw-menu>
        </app-collapse-item>
      </app-collapse>
    </b-card>
  </div>
</template>
<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { BCard } from "bootstrap-vue";
import CreateRawMenu from "./CreateRawMenu.vue";
import CreateMenuItemFromPages from "./CreateMenuItemFromPages.vue";
export default {
  emits: ["refetch"],
  props: {
    menuId: {
      type: [String, Number],
    },
    lastPriority: {
      type: [String, Number],
    },
    languageId: {
      type: [String, Number],
      required: false,
    },
  },
  methods: {
    refetch() {
      this.$emit("refetch");
    },
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    CreateRawMenu,
    CreateMenuItemFromPages,
  },
};
</script>
