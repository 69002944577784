<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>لیست فهرست ها</h1>
      </div>
      <b-row align-content="center">
        <b-col cols="10" align-self="center">
          <menu-picker @getMenu="setMenu"></menu-picker>
        </b-col>
        <b-col cols="2" align-self="center">
          <b-button
            :disabled="!tempMenu"
            variant="primary"
            @click="getMenuItems"
          >
            گزینش
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <menu-sidebar
            :lastPriority="lastPriority"
            :menuId="selectedMenu ? selectedMenu.menuId : null"
            @refetch="getAllMenuItems"
            :languageId="selectedMenu ? selectedMenu.languageId : null"
          ></menu-sidebar>
        </b-col>
        <b-col cols="12" md="8">
          <main-menu
            :menuItemList="menuItemsList"
            @handlePriorities="handlePriorities"
            @refetch="getAllMenuItems"
          ></main-menu>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "فهرست ها",
  data() {
    return {
      isLoading: false,
      selectedMenu: null,
      tempMenu: null,
      menuItemsList: null,
      lastPriority: null,
      childCount: 0,
      newItemPriorities: [],
    };
  },
  methods: {
    handlePriorities() {
      this.newItemPriorities = [];
      this.menuItemsList.forEach((item, index) =>
        this.recurse(item, null, index + 1)
      );
      this.sendChangePrioritiesRequest();
    },
    async sendChangePrioritiesRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let changeItemPriorities = new ChangeItemPriorities(_this);
        changeItemPriorities.setRequestParamDataObj(_this.newItemPriorities);
        await changeItemPriorities.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `ساختار فهرست ویرایش شد.`,
              },
            });
            _this.getAllMenuItems();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setMenu(menu) {
      this.tempMenu = menu;
    },
    recurse(node, parentId, priority) {
      this.newItemPriorities.push({
        menuItemId: node.menuItemId,
        parentId,
        priority,
      });
      for (let i = 0, count = node.children.length; i < count; i++) {
        if (node.children.length > 0) {
          this.recurse(node.children[i], node.menuItemId, i + 1);
        }
      }
    },
    getMenuItems() {
      this.selectedMenu = this.tempMenu;
      this.getAllMenuItems();
    },
    async getAllMenuItems() {
      try {
        this.isLoading = true;
        let _this = this;
        let getAllMenuItems = new GetAllMenuItems(_this);
        getAllMenuItems.setRequestParam({
          count: 0,
          pageNumber: 0,
          menuId: _this.selectedMenu.menuId,
          nested: true,
        });
        await getAllMenuItems.fetch((response) => {
          if (response.isSuccess) {
            _this.menuItemsList = response.data.menuItems;
            _this.menuItemsList.sort((a, b) => a.priority - b.priority);
            console.log(_this.menuItemsList);
            if (_this.menuItemsList && _this.menuItemsList.length > 0) {
              _this.lastPriority =
                _this.menuItemsList[_this.menuItemsList.length - 1].priority;
              console.log(_this.lastPriority);
            }
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    MenuPicker,
    MenuSidebar,
    MainMenu,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import MenuPicker from "@/views/components/utils/MenuPicker.vue";
import MenuSidebar from "@/views/components/Menu/MenuSidebar.vue";
import MainMenu from "@/views/components/Menu/MenuMain.vue";
import { GetAllMenuItems, ChangeItemPriorities } from "@/libs/Api/MenuItem";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
</script>
